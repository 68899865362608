<template>
  <div id="content" v-if="$store.state.month">
    <div id="informationContent">
      <template v-for="(contact, n) in $store.state.information">
        <div v-if="contact.type === 'text'" class="projectSection" :key="'ctext' + n">
          <div class="projectSectionLeft">
            <div class="projectSectionTitle" :class="$store.state.monthComp">{{ contact.title }}</div>
          </div>

          <div class="projectSectionRight">
            <div class="projectBox" v-html="contact.text"></div>
          </div>
        </div>

        <div v-else-if="contact.type === 'title'" class="title" :key="'ctitle' + n" :id="contact.title.toLowerCase()">
          <div class="titleInner" v-html="contact.title"></div>
        </div>

        <template v-else-if="contact.type === 'newsletter'">
          <div class="title" :key="'ctitle' + n" id="newsletter">
            <div class="titleInner" v-html="contact.title"></div>
          </div>

          <div class="projectSection" :key="'cnew' + n">
            <div class="projectSectionLeft">
              <div class="projectSectionTitle" :class="$store.state.monthComp">Newsletter</div>
            </div>

            <div class="projectSectionRight">
              <div class="projectBox newsletter">
                <div>Sign up to our newsletter:</div>

                <form method="post" action="https://newsletter.infomaniak.com/external/submit" class="inf-form" target="_blank">
                  <input type="email" name="email" style="display: none" />
                  <input
                    type="hidden"
                    name="key"
                    value="eyJpdiI6IjBlbWtlYUtBNVFjREJkelVTRnlzZktiV0VcL1JkTGpWTHpUalFySmVYaG5FPSIsIm1hYyI6ImRkOTFlNzc2ZjY5NjVjNWNjNzUwMWU5MDBiZTA0ZjcxODJhMTY0MGU5Mjk3ZDgyMjllNTdhZDJjM2ZmZjM0Y2YiLCJ2YWx1ZSI6Ik9mUFMxSXJOaGMwdXdxSVhFRzM1bzAzYVMwM0REdW1PZjFyTFBTdHN6VHM9In0="
                  />
                  <input type="hidden" name="webform_id" value="19814" />

                  <div class="mc-field-group">
                    <label>Email Address<span class="asterisk">*</span></label>
                    <input type="email" value="" name="inf[1]" v-model="email" autocomplete="off" />
                  </div>

                  <div class="mc-field-group">
                    <label>First Name</label>
                    <input type="text" value="" name="inf[2]" autocomplete="off" />
                  </div>

                  <div class="mc-field-group">
                    <label>Last Name</label>
                    <input type="text" value="" name="inf[3]" autocomplete="off" />
                  </div>

                  <div class="mc-field-group">
                    <label>Company</label>
                    <input type="text" value="" name="inf[67671]" autocomplete="off" />
                  </div>

                  <input type="submit" id="mc-embedded-subscribe" :class="[$store.state.month, { validated: !errors.length }]" value="Subscribe" name="subscribe" />
                </form>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ApiMixins from '@/components/A/Mixins/ApiMixins';

export default {
  data() {
    return {
      errorMessage: false,
      errors: [],
      email: '',
    };
  },

  mixins: [ApiMixins],

  created() {
    this.checkForm(this.email);
  },

  watch: {
    email: function (val) {
      this.checkForm();
    },
  },

  methods: {
    checkForm: function () {
      this.errors = [];
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }

      if (!this.errors.length) {
        this.errorMessage = false;
        return true;
      }
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.mc-field-group {
  width: 100%;
  margin-bottom: $objectPadding;
  display: grid;
  grid-template-columns: repeat(8, 1fr);

  label {
    grid-column: span 3;
    position: relative;
  }

  input {
    grid-column: span 5;
    padding: $boxPadding;
    border: $border-square;
    flex: 1;
    border-radius: 0;
  }
}

#mc-embedded-subscribe {
  border: $border-square;
  margin: $objectPadding 0;
  cursor: pointer;
  width: 100%;
  padding: $boxPadding;
  opacity: 0.5;
  pointer-events: none;
  border-radius: 10rem;
}

#mc-embedded-subscribe.validated {
  pointer-events: auto;
  opacity: 1;
}

#mc-embedded-subscribe.validated:hover {
  background-color: black;
  color: white;
}

#informationContent {
  padding: 0 $mainPadding $mainPadding;
}

.info .title {
  margin: 1rem 0 -2rem;
}

form {
  margin-top: $mainPadding;
}

@media screen and (max-width: $breakPoint1) {
  .info .title {
    margin-bottom: 0;
  }

  .mc-field-group {
    label {
      margin-bottom: $objectPadding;
    }

    label,
    input {
      grid-column: span 8;
    }
  }
}
</style>

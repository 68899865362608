<template>
  <div id="content">
    <slideshow v-if="$store.state.images" :images="$store.state.images"></slideshow>

    <div class="intro" :class="$store.state.monthComp" v-if="$store.state.intro && $store.state.intro !== ''">
      <div class="introSection">
        <div class="projectBox" v-html="$store.state.intro"></div>
      </div>
    </div>

    <div v-if="$store.state.dates.length || ($store.state.calendarText && $store.state.calendarText !== '')" class="title sticky">Calendar {{ firstYear }}</div>

    <div class="intro" :class="$store.state.month" v-if="!$store.state.length && $store.state.calendarText && $store.state.calendarText !== ''">
      <div class="introSection">
        <div class="projectBox" v-html="$store.state.calendarText"></div>
      </div>
    </div>

    <div v-for="(year, name, index) in $store.state.dates" :class="[index === 0 ? 'firstYear' : '']" :key="year + index">
      <div class="title" v-if="index !== 0">Tour Dates {{ name }}</div>

      <div v-for="months in year" class="calendarMonth" :class="months.month" :key="year + months.month">
        <div class="monthName">
          <div class="monthNameInner">
            {{ months.month }}
          </div>
        </div>

        <div class="events">
          <month v-for="(performance, index) in orderDays(months.performances)" :performance="performance" :key="performance.id" :index="index" :month="months.month"></month>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiMixins from '@/components/A/Mixins/ApiMixins';
import lodash from 'lodash';
import Month from '@/components/A/Calendar/Month';
import Slideshow from '@/components/Single/Slideshow';

export default {
  data() {
    return {
      firstYear: false,
      images: false,
    };
  },

  components: {
    Slideshow,
    Month,
  },

  mixins: [ApiMixins],

  methods: {
    orderDays(value) {
      return lodash.orderBy(value, 'days', 'asc');
    },
  },

  created() {
    if (this.$store.state.dates) {
      this.firstYear = Object.keys(this.$store.state.dates)[0];
    } else {
      this.$axios.get('/calendar').then((result) => {
        this.$store.commit('dates', result.data.dates);
        this.$store.commit('images', result.data.images);
        this.$store.commit('intro', result.data.intro);
        this.$store.commit('calendarText', result.data.calendartext);
        this.firstYear = Object.keys(this.$store.state.dates)[0];
      });
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#content {
  position: relative;
  min-height: 100vh;
}

.intro {
  padding: $mainPadding;
  // margin-bottom: $titleSpacing;

  p:last-child {
    margin: 0;
  }
}

.firstYear {
  margin-top: $titleSpacing;
}

.calendarMonth {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: $objectPadding;
  padding: $mainPadding;
}

.monthName {
  padding: $boxPadding;
  grid-column: span 1;
  text-align: center;
  border: $border-square;
}

.monthNameInner {
  position: sticky;
  top: 1rem;
}

.events {
  grid-column: span 5;
}

.event {
  margin-bottom: $projectSpacing;
}

div.event:last-child {
  margin-bottom: 0;
}

.eventContent {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: $objectPadding;
}

.eventImage {
  grid-column: span 2;
  min-height: 11rem;
  position: relative;
}

.eventInfo {
  padding: $boxPadding;
  border: $border-square;
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  font-size: $smallText;
  line-height: $smallLine;
}

.eventText {
  padding: $boxPadding;
  border: $border-square;
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  font-size: $smallText;
  line-height: $smallLine;
}

.eventName {
  flex: 1;
}

.days {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: $objectPadding;
  grid-row-gap: $objectPadding;
}

.day {
  text-align: center;
  padding: $boxPadding;
  padding-left: 0;
  padding-right: 0;
  border: $border-square;
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.07rem;
}

.singleDay {
  grid-column: span 2;
}

@media screen and (max-width: $breakPoint1) {
  .eventContent {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  .eventInfo {
    grid-column: span 2;
  }

  .days {
    grid-column: span 1;
  }

  .eventImage {
    grid-column: span 1;
  }

  .eventLink {
    margin-top: $lineHeight;
  }
}

@media screen and (max-width: $breakPoint1) {
  .firstYear {
    margin-top: 0;
  }

  .calendarMonth {
    grid-row-gap: $objectPadding;
  }

  .monthName {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-column: span 6;
    height: 4rem;
    margin-bottom: $mainPadding;
  }

  .monthNameInner {
    position: relative;
    top: auto;
  }

  .events {
    grid-column: span 6;
  }
}
</style>

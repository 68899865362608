<template>
  <div class="projectSection" v-if="project.auteurs">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Project By</div>
    </div>

    <div class="projectSectionRight">
      <template v-for="item in project.auteurs">
        <router-link v-if="item.texts > 0" :to="'/about/auteur/' + item.url" class="noUnderline">
          <div class="projectTitle auteurTitle" v-html="item.title"></div>
        </router-link>
        <div v-else class="projectTitle auteurTitle" v-html="item.title"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['project'],
};
</script>

<template>
  <div id="content">
    <filters v-if="$store.state.projects"></filters>
    <div v-if="$store.state.projects" class="title">{{ filterTitle }}</div>
    <router-view :type="type" v-if="$store.state.projects"></router-view>
  </div>
</template>

<script>
import ApiMixins from '@/components/A/Mixins/ApiMixins';
import Filters from '@/components/Filters';

export default {
  data() {
    return {
      // filterTitle: false,
      type: false,
    };
  },

  components: {
    Filters,
  },

  watch: {
    $route(to, from) {
      this.setTitle();
    },
  },

  methods: {
    setTitle() {
      this.type = this.$route.params.type;
    },
  },

  computed: {
    filterTitle() {
      let type = this.$route.params.type;
      let title = '';
      if (this.type === 'all') {
        title = 'All Projects';
      } else {
        if (this.$store.state.types) {
          let filter = this.$store.state.types.filter((item) => {
            return item.url === this.$route.params.type;
          });
          title = filter[0].title;
        }
      }

      return title;
    },
  },

  mixins: [ApiMixins],

  created() {
    console.log('created');
    this.setTitle();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#projects {
  padding: 0 $mainPadding $mainPadding;
}

.projects {
  padding: 0 $mainPadding 0;
}

.projects:last-child {
  padding-bottom: $mainPadding;
}

.project {
  padding-bottom: $projectSpacing;
}

.project:last-child {
  padding-bottom: $mainPadding;
}

.projectContent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: $objectPadding;
}

@media screen and (max-width: $breakPoint1) {
  #projects {
    .projectThumb {
      display: none;
    }

    .thumbAll.projectThumb {
      display: block;
    }

    .projectBoxNo,
    .projectBox {
      grid-column: span 6;
    }
  }
}
</style>
